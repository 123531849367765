import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Timepicker } from 'react-timepicker';
import axios from 'axios';
import HourTimePicker from './inputs/HourTimePick';
import HourTimePick from './inputs/HourTimePick';

const times = [];
for (let hour = 7; hour < 22; hour++) {
  for (let minute = 0; minute < 60; minute += 30) {
    const formattedTime = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
    times.push(formattedTime);
  }
}

const BookingForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [step, setStep] = useState(1);
  const [starttime, setStartTime] = useState(null);
  const [endtime, setEndTime] = useState(null);
  const [timeDisabled, setTimeDisabled] = useState(false);
  const [acceptMessage, setAcceptMessage] = useState(false);
  const feldname = "Feld 1";
  const [error, setError] = useState(null);
  const [fields, setFields] = useState([
    { id: '66d549cad9a63f73dec538f0', name: "Feld 1" },
    { id: '66d5c48e212bb1880fb7394b', name: "Feld 2" },
    { id: '66d5c4b9212bb1880fb73955', name: "Feld 3" }
  ]);  
  const [importMessage, setImportMessage] = useState(
    "Zur Zeit sind Buchungen nur mit Barzahlung möglich."
  )
  const [message, setMessage] = useState(null);
  const [bookings, setBookings] = useState([]);

  const openingHours = {
    start: '07:00',
    end: '22:00',
  };

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    date: null,
    feld: null,
    starttime: "",
    endtime: "",
    savedata: false,
    hallrules: false,
    bookingroules: false,
  });

  useEffect(() => { 
    const fetchBuchungen = async () => {
        try {
            const $userID = 'INT1722793920824';
            const response = await axios.get(`/get_booking/${$userID}`);
            setBookings(response.data);
        } catch (error) {
            console.error("Fehler beim Laden der Buchungen:", error);
        }
    };
    fetchBuchungen();
  }, []);

  useEffect(() => {
      console.log("Aktuelle Buchungen:", bookings);
  }, [bookings]);

  async function fetchBuchungen() {
    try {
      const $userID = 'INT1722793920824';
      const response = await axios.get(`get_booking/${$userID}`);

      const buchungen = response.data;

      return buchungen;
    } catch (error) {
      console.error('Fehler beim Abrufen der Buchungen:', error);
      return [];
    }
  }

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckboxChange = (field) => {
    setFormData({
      ...formData,
      feld: field,
    });
  };

  const handleDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    setStartDate(date);
    setFormData({
      ...formData,
      date: date,
    });
  };

  const isTimeBooked = (time) => {
    if (!formData.date || !formData.feld) return false;
    
    // Filtere die Buchungen für das gewählte Datum und Feld
    return bookings.some((booking) => {
      return (
        formData.date === booking.date && // Datum stimmt überein
        booking.field === feldname && // Feld stimmt überein
        booking.startTime <= time && // Startzeit der Buchung ist kleiner oder gleich der ausgewählten Zeit
        time <= booking.endTime // Endzeit der Buchung ist größer oder gleich der ausgewählten Zeit
      );
    });
  };

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  
  const handleStartTimeChange = (time) => {
    setStartTime(time);
    updateFormData('starttime', time);
  };
  
  const handleEndTimeChange = (time) => {
    setEndTime(time);
    updateFormData('endtime', time);
  };

  const validateTimeStep = () => {
    const [startHours, startMinutes] = starttime.split(':').map(Number);
    const [endHours, endMinutes] = endtime.split(':').map(Number);

    const openingStart = new Date();
    const openingEnd = new Date();
    const selectedStartTime = new Date();
    const selectedEndTime = new Date();

    const [openingStartHours, openingStartMinutes] = openingHours.start.split(':').map(Number);
    const [openingEndHours, openingEndMinutes] = openingHours.end.split(':').map(Number);

    openingStart.setHours(openingStartHours, openingStartMinutes, 0, 0);
    openingEnd.setHours(openingEndHours, openingEndMinutes, 0, 0);

    selectedStartTime.setHours(startHours, startMinutes, 0, 0);
    selectedEndTime.setHours(endHours, endMinutes, 0, 0);

    if (selectedStartTime === ""){
      return false;
    }

    if (selectedEndTime === ""){
      return false;
    }

    if (selectedStartTime < openingStart || selectedEndTime > openingEnd) {
      alert(`Die Buchungszeiten müssen innerhalb der Öffnungszeiten liegen: ${openingHours.start} - ${openingHours.end}.`);
      return false;
    }

    if (startMinutes % 30 !== 0 || endMinutes % 30 !== 0) {
      alert('Die Start- und Endzeiten müssen in 30-Minuten-Schritten erfolgen.');
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (formData.date && formData.date.getTime() === today.getTime()) {
      const currentTime = new Date();
      if (selectedStartTime < currentTime) {
        alert('Die Startzeit darf nicht in der Vergangenheit liegen.');
        return false;
      }
    }

    return true;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step < 3) {
      if(step === 1){
        if(formData.name === "" || formData.email === ""){
          alert("Alle Felder sind notwendig.");
          return;
        } else if(formData.name === ""){
          alert("Bitte gebe deinen Namen ein.");
          return;
        } else if(formData.email === ""){
          alert("Bitte gebe deine E-Mail-Adresse ein.");
          return;
        } else if(!formData.savedata || acceptMessage === false) {
          setMessage('Wenn du das Feld nicht auswählst müssen deine zukünftigen Buchungen vom Admin bestätigt werden.');
          setStep(step);
          setAcceptMessage(true);
        }
        if(acceptMessage === true){
          setStep(step + 1);
        }
      }
      if(step === 2){
        if(formData.date === "" || formData.feld === ""){
          alert("Alle Felder sind notwendig.");
          return;
        } else if(formData.date === ""){
          alert("Bitte wähle ein Datum aus.");
          return;
        } else if(formData.feld === ""){
          alert("Bitte wähle ein Feld aus.");
          return;
        } else {
          setStep(step + 1);
        }
      }
      if(step === 3) {

      }
    } else {
      setStep(4);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    } else {
      setStep(1);
    }
  };

  const handleTimeClick = (time) => {
    if (isTimeBooked(time)) return; // Buchungszeit darf nicht gewählt werden
    if (!starttime) {
      setStartTime(time);
      updateFormData('starttime', time);
      setEndTime(null); // Endzeit zurücksetzen, da neue Startzeit gewählt wurde
    } else if (!endtime) {
      if (time < starttime) {
        setStartTime(time);
        updateFormData('starttime', time);
      } else {
        setEndTime(time);
        updateFormData('endtime', time);
      }
    } else {
      setStartTime(time);
      updateFormData('starttime', time);
      setEndTime(null);
    }
  };

  const isTimeInRange = (time) => {
    if (!starttime || !endtime) return false;
    return starttime <= time && time <= endtime;
  };
  
  const calculateDuration = () => {
    if (!starttime || !endtime) return null;
  
    const [startHour, startMinute] = starttime.split(':').map(Number);
    const [endHour, endMinute] = endtime.split(':').map(Number);
  
    const startDate = new Date(0, 0, 0, startHour, startMinute);
    const endDate = new Date(0, 0, 0, endHour, endMinute);
  
    const diff = (endDate - startDate) / 1000 / 60; // Differenz in Minuten
    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
  
    return `${hours} Stunden ${minutes} Minuten`;
  };

  const getInstructionText = () => {
    if (!starttime) {
      return "Wähle die Startzeit aus";
    } else if (!endtime) {
      return "Wähle die Endzeit aus";
    } else {
      return "";
    }
  };

  const sendData = async () => {
    const id = 'INT1722793920824';
    const url = `https://next.agiplus.de/api/v1/genx/calender/${id}`;

    try {
      const request = await axios.post(url, formData);
      alert('Deine Buchung wurde erfolgreich übermittelt.');

      setFormData({
        email: "",
        name: "",
        date: null,
        feld: null,
        starttime: "",
        endtime: "",
        savedata: false,
        hallrules: false,
        bookingroules: false,
      });
      setStep(1);

    } catch (error) {
      alert('Es gab einen Fehler bei deiner Buchung. Bitte versuche es erneut.');
    }
  };

  const checkForConflicts = () => {
    if (!formData.date || !formData.starttime || !formData.endtime) return false;
  
    const selectedDate = new Date(formData.date);
    selectedDate.setDate(selectedDate.getDate() + 1);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    const selectField = formData.feld;
  
    const startTime = formData.starttime;
    const endTime = formData.endtime;
  
    for (const booking of bookings) {
      const bookingDate = booking.date.toISOString().split('T')[0];
      const bookingField = booking.feld;
      if (bookingDate === bookings) {
        if(bookingField === selectField){
          if (startTime < booking.endTime && endTime > booking.startTime) {
            return true;
          }
        }
      }
    }
  
    return false;
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            {/*<div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-white"
              >
                Vollständiger Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-600 dark:focus:border-green-600"
                placeholder="Gebe deinen Namen ein"
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white"
              >
                Deine E-Mail
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-600 dark:focus:border-green-600"
                placeholder="Gebe deine E-Mail an"
                required
              />
            </div>
            {message ? (
              <div className="text-green-600 border border-green-600 p-2.5 text-sm mb-4 rounded-lg">
                {message}
              </div>
            ) : null}
            <div className="flex items-center mb-4">
              <input
                id="savedata"
                type="checkbox"
                checked={formData.savedata}
                onChange={handleInputChange}
                className="w-4 h-4 text-green-600 bg-gray-100 rounded focus:ring-green-600 dark:focus:ring-green-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                htmlFor="savedata"
                className={`ms-2 text-sm font-medium ${
                  message ? "text-green-600" : "text-gray-300"
                }`}
              >
                Daten speichern für folgende Buchungen.
              </label>
            </div>*/}
          </>
        );
      case 2:
        return (
          <>
            <div className="flex flex-col">
              <label className="mb-2 font-semibold text-white text-lg">Datum:</label>
              <DatePicker
                selected={formData.date}
                onChange={handleDateChange}
                dateFormat="dd/MM/yy"
                id="date"
                className="mt-1 block w-[280px] px-3 py-2 border mb-4 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholderText="Wähle ein Datum aus"
                dayClassName={(date) =>
                  date < new Date() ? "red-border" : undefined
                }
                filterDate={(date) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return date >= today;
                }}
              />
            </div>
            <div>
              <h3 className="mb-2 font-semibold text-white text-lg">Feld</h3>
              <ul className="items-center w-[280px] mb-4 text-sm font-medium text-white bg-white border border-gray-200 rounded-lg sm:flex">
              {fields.map((feld) => (
                <li key={feld.id} className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                  <div className="flex items-center ps-3">
                    <input
                      id={feld.id}
                      checked={formData.feld === feld.id}
                      type="checkbox"
                      value={feld.id}
                      onChange={() => handleCheckboxChange(feld.id)}
                      className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-600 dark:focus:ring-green-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor={feld.id}
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {feld.name}
                    </label>
                  </div>
                </li>
              ))}
              </ul>
            </div>
            <div className="flex gap-8 mb-8">
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div>
              <h3 className='text-white'>{getInstructionText()}</h3>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '8px', margin: '20px 0' }}>
              {times.map((time) => {
                const isBooked = isTimeBooked(time); // Überprüfen, ob die Zeit bereits gebucht ist
                const isInRange = isTimeInRange(time); // Überprüfen, ob die Zeit innerhalb des ausgewählten Bereichs liegt
                const isStartTime = time === starttime;
                
                return (
                  <a
                    key={time}
                    onClick={() => !isBooked && handleTimeClick(time)} // Wenn die Zeit bereits gebucht ist, darf sie nicht geklickt werden
                    className={`p-2 flex justify-center align-middle ${isInRange || isStartTime ? 'bg-green-500 text-white' : isBooked ? 'bg-red-500 text-white' : 'bg-gray-100'}`}
                    style={{
                      borderRadius: '2px',
                      cursor: isBooked ? 'not-allowed' : 'pointer', // Gebuchte Zeiten können nicht angeklickt werden
                    }}
                  >
                    {time}
                  </a>
                );
              })}
              </div>
              <div className='flex flex-col gap-2'>
                <h4 className='text-white'>Buchungszeit</h4>
                <div className='flex gap-8'>
                  <p className='text-white'>Von: {starttime || '...'}</p>
                  <p className='text-white'>Bis: {endtime || '...'}</p>
                </div>
                {starttime && endtime && (
                  <p className='text-white'>Dauer: {calculateDuration()}</p>
                )}
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="mb-5">
              <p className="text-white text-lg font-bold mb-4">
                Bestätigung Ihrer Eingaben:
              </p>
              <ul className="text-white">
                <li>Email: {formData.email}</li>
                <li>Name: {formData.name}</li>
                <li>Datum: {formData.date ? formData.date.toLocaleDateString() : ""}</li>
                <li>Feld: {formData.feld}</li>
                <li>Start: {formData.starttime}</li>
                <li>Ende: {formData.endtime}</li>
                <li>Daten speichern: {formData.savedata ? "Ja" : "Nein"}</li>
              </ul>
            </div>
            <fieldset>
              <legend className="sr-only">Vereinbarungen</legend>
              <div className="flex items-center mb-4">
                <input
                  id="hallrules"
                  type="checkbox"
                  checked={formData.hallrules}
                  onChange={handleInputChange}
                  className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-600 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  required
                />
                <label
                  htmlFor="hallrules"
                  className="ms-2 text-xs font-medium text-gray-300"
                >
                  Hiermit stimme ich der{" "}
                  <a href="#" className="text-white hover:underline">
                    Hallenverordnung
                  </a>{" "}
                  zu. *
                </label>
              </div>
              <div className="flex items-center mb-8">
                <input
                  id="bookingroules"
                  type="checkbox"
                  checked={formData.bookingroules}
                  onChange={handleInputChange}
                  className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-600 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  required
                />
                <label
                  htmlFor="bookingroules"
                  className="ms-2 text-xs font-medium text-gray-300"
                >
                  Hiermit stimme ich der{" "}
                  <a href="#" className="text-white hover:underline">
                    Buchungsvereinbarung
                  </a>{" "}
                  zu. *
                </label>
              </div>
            </fieldset>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="grid lg:grid-cols-12 md:grid-cols-8 grid-cols-4 lg:gap-[20px] md:gap-[16px] md:mx-[32px] mx-[12px] gap-[12px]"
      id="booking-form"
    >
      <div className="grid lg:col-span-12 md:col-span-8 col-span-4 text-center mb-8">
        <h2 className="text-white lg:text-4xl text-3xl font-bold mb-4">Buchung</h2>
        <p className="text-gray-300 lg:text-lg text-sm mb-4">Hier kannst du unsere Felder mieten</p>
        {importMessage ? (
          <div className="text-green-600 border border-green-600 p-2.5 text-[14px] mb-4 rounded-lg w-fit mx-auto">
            {importMessage}
          </div>
        ) : null}
      </div>
  
      <div className="flex lg:col-span-6 flex-col lg:mr-10 pt-[8px] pb-[16px] md:col-span-8 md:mr-10 md:ml-10 col-span-4">
        <ol className="flex h-[60px] w-100 mb-10 text-sm font-medium text-center text-gray-500 dark:text-gray-400 justify-between">
          <li className={`flex md:w-full items-center ${step === 1 || step >= 1 ? "text-green-600" : ""}`}>
            <span className="flex items-center text-sm">
              {step >= 1 ? (
                <svg
                  className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              ) : (
                <span className="me-1">1</span>
              )}
              Persönliche <span className="hidden sm:inline-flex sm:ms-2">Infos</span>
            </span>
          </li>
          <li className={`flex md:w-full items-center ${step === 2 || step >= 2 ? "text-green-600" : ""}`}>
            <span className="flex items-center text-sm">
              {step >= 2 ? (
                <svg
                  className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              ) : (
                <span className="me-2">2</span>
              )}
              Buchungs <span className="hidden sm:inline-flex sm:ms-2">Details</span>
            </span>
          </li>
          <li className={`flex items-center ${step === 4 ? "text-green-600" : ""}`}>
            <span className="flex items-center text-sm">
              {step >= 4 ? (
                <svg
                  className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              ) : (
                <span className="me-3">3</span>
              )}
              Bestätigung
            </span>
          </li>
        </ol>
  
        <form className="max-w-sm mx-auto" onSubmit={handleNext}>
          {renderStep()}
          <div className="flex justify-between">
            {step > 1 ? (
              <button
                type="submit"
                onClick={handleBack}
                className="text-white bg-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600"
              >
                {step <= 4 ? "Zurück" : ""}
              </button>
            ) : (
              <span className=""></span>
            )}
  
            {step < 4 ? (
              <button
                type="submit"
                onClick={handleNext}
                className="text-white bg-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600"
              >
                {step < 4 ? "Weiter" : ""}
              </button>
            ) : (
              <span className=""></span>
            )}
  
            {step === 4 ? (
              <button
                type="submit"
                onClick={sendData}
                className="text-white bg-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600"
              >
                Abschließen
              </button>
            ) : (
              <span className=""></span>
            )}
          </div>
        </form>
      </div>
  
      <div className="lg:grid lg:col-span-6 md:col-span-8 col-span-4 hidden">
        <img className="h-[100%]" src="/assets/img/courts/court-1.jpeg" alt="" />
      </div>
    </div>
  );  
};

export default BookingForm;